import { weightedRandom } from './utils';

import { Auth } from './auth';

import '../assets/css/game.css';
import '../assets/css/common.css';

class Game {
    private CHARS: object = {};
    private inPlay: boolean = false;
    private score: number = 0;
    private level: number = 1;
    private interval: number = 0;
    private perLevelScore: {[LevelTxt: string]: number} = {'Easy': 5, 'Medium': 20, 'Hard': 30};
    private levelThresholds: {[LevelTxt: string]: number} = {'Easy': 5, 'Medium': 10, 'Hard': 15};
    private levelCountdownTimer: {[LevelTxt: string]: number} = {'Easy': 45, 'Medium': 90, 'Hard': 180};

    private game: string = 'Whackamok';
    private gameUuid: string = 'default';
    private coverImg: string = '';
    private fieldImg: string = '';

    private numHoles: number = 5
    private holePosn: Array<Array<number>> = [];
    private holeDim = [72, 47]; // Hole is 72px x 47px
    private dw = document.documentElement.clientWidth;
    private dh = document.documentElement.clientHeight;

    private maxCols: number = Math.round((this.dw - this.holeDim[0]) / this.holeDim[0]);
    private maxRows: number = Math.round(2 * (this.dh - this.holeDim[1]) / (3 * this.holeDim[1]));

    private chars: Array<string> = [];
    private whackChar: string;
    private dispChars: Array<string>;

    private audioOn: boolean = true;
    private audios: {[key: string]: Uint8Array} = {};

    private hits: number = 0;
    private misses: number = 0;

    private playArea: HTMLElement;
    private levelEl: HTMLElement;
    private scoreContainer: HTMLElement;
    private hitsContainer: HTMLElement;
    private missesContainer: HTMLElement;
    private countDownTimerContainer: HTMLElement;

    private countDownTimer: number;
    private countDownTimerInt: number;

    private token: string = localStorage.getItem('token') as string || '';
    private username: string = localStorage.getItem('username') as string || '';

    renderDOM() {
        let $this = this;
        let tmpl = document.querySelector('template#game') as HTMLElement;
        let contents = eval('`' + tmpl.innerHTML + '`');
        (document.querySelector('.game') as HTMLElement).innerHTML = contents;

        let coverImg = document.querySelector('.cover') as HTMLDivElement;
        coverImg.style['height'] = '' + ($this.dh / 3) + 'px';
        coverImg.style['background'] = `url('${$this.coverImg}')`;

        $this.playArea = document.querySelector('.field') as HTMLDivElement;
        $this.playArea.style['height'] = '' + (2 * $this.dh / 3) + 'px';
        $this.playArea.style['background'] = `url('${$this.fieldImg}')`;
    }

    createMetaEls() {
        let $this = this;

        (document.querySelector('.meta') as HTMLDivElement).classList.remove('hidden');

        $this.levelEl = document.querySelector('.meta .level span') as HTMLSpanElement;
        $this.levelEl.innerText = $this.getLevelTxt();

        $this.scoreContainer = document.querySelector('.meta .score span') as HTMLSpanElement;
        $this.scoreContainer.innerText = $this.score.toString();

        $this.hitsContainer = document.querySelector('.meta .hits span') as HTMLSpanElement;
        $this.hitsContainer.innerText = $this.hits.toString();

        $this.missesContainer = document.querySelector('.meta .misses span') as HTMLSpanElement;
        $this.missesContainer.innerText = $this.misses.toString();

        $this.countDownTimerContainer = document.querySelector('.meta .timer span') as HTMLSpanElement;
        $this.countDownTimerContainer.innerText = '0';

        let audioTogglerEl = document.querySelector('.meta .audio a') as HTMLAnchorElement;
        audioTogglerEl.innerText = ($this.audioOn === true ? 'ON' : 'OFF');
        audioTogglerEl.addEventListener('click', function(ev: Event) {
            if (audioTogglerEl.innerText === 'ON') {
                audioTogglerEl.innerText = 'OFF';
                $this.audioOn = false;
            } else {
                audioTogglerEl.innerText = 'ON';
                $this.audioOn = true;
                $this.initAudios();
            }
        });
    }

    getLevelTxt(): string {
        let levelTxt = '';
        if (this.level < this.levelThresholds['Easy']) {
            levelTxt = 'Easy';
        } else if (this.level < this.levelThresholds['Medium']) {
            levelTxt = 'Medium';
        } else {
            levelTxt = 'Hard';
        }
        return levelTxt;
    }

    initTimer() {
        let $this = this;
        clearInterval($this.countDownTimerInt);
        let levelTxt = $this.getLevelTxt();
        $this.countDownTimerContainer.innerText = $this.levelCountdownTimer[levelTxt].toString();
        $this.countDownTimer = $this.levelCountdownTimer[levelTxt];
        $this.countDownTimerInt = setInterval(function() {
            $this.countDownTimer -= 1;
            if ($this.countDownTimer === 0) {
                $this.end();
            }
            $this.countDownTimerContainer.innerText = $this.countDownTimer.toString();
        }, 1000);
    }

    updateInterval() {
        let levelAmp = 1;
        if (this.level < this.perLevelScore['Easy']) {
            levelAmp = 1;
        } else if (this.level < this.perLevelScore['Medium']) {
            levelAmp = 2;
        } else {
            levelAmp = 3;
        }
        this.interval = 1000 * Math.exp(- 0.01 * levelAmp * this.level);
    }

    playRandomAudio(char: string) {
        let selChar = this.CHARS[char];
        if (selChar['audio'].length > 0) {
            let idx = Math.floor(Math.random() * selChar['audio'].length);
            this.playAudio(selChar['audio'][idx]);
        }
    }

    initAudios() {
        let $this = this;
        if ($this.audioOn === true) {
            let enChars = $this.chars;
            for (let ii = 0; ii < enChars.length; ii++) {
                let audios = this.CHARS[enChars[ii]]['audio'];
                for (let jj = 0; jj < audios.length; jj++) {
                    let src = audios[jj];
                    if (typeof $this.audios[src] === 'undefined') {
                        fetch(src).then(function(data) {
                            data.arrayBuffer().then(function(buffer) {
                                $this.audios[src] = buffer;
                            });
                        });
                    }
                }
            }
        }
    }

    playAudio(src: string) {
        let $this = this;
        let AudioContext = window.AudioContext || window.webkitAudioContext;
        let context = new AudioContext();
        let source = context.createBufferSource();
        let newBuff = ($this.audios[src] || []).slice(0);
        if (newBuff.byteLength > 0) {
            context.decodeAudioData(newBuff, function(buffer) {
                source.buffer = buffer;
                // source.playbackRate.value = 1000 / $this.interval;
                source.connect(context.destination);
                source.start(0);
                setTimeout(function() {
                    context.close();
                }, 1000);
            });
        }
    }

    startWhack(_: Event) {
        let dd: HTMLElement = document.querySelector('.field') as HTMLElement;
        dd.classList.add('whack');
    }

    endWhack(_: Event) {
        let dd: HTMLElement = document.querySelector('.field') as HTMLElement;
        dd.classList.remove('whack');
    }

    initCursor() {
        let $this = this;
        document.body.addEventListener('mousedown', (ev) => $this.startWhack(ev));
        document.body.addEventListener('mouseup', (ev) => $this.endWhack(ev));
    }

    initHoles() {
        if (this.level % 5 === 0) {
            this.numHoles += 1;
        }
        this.holePosn = [];
        document.querySelectorAll('.hole').forEach(ii => ii.remove());
        document.querySelectorAll('.char').forEach(ii => ii.remove());
        let rw = 0;
        let rh = 0;
        let maxPossHoles = (this.maxRows - 2) * (this.maxCols - 2);
        let randomIdxPicks: Array<number> = [];
        for (let ii = 0; ii < this.numHoles; ii++) {
            let holeIdx = Math.round(Math.random() * maxPossHoles);
            while (randomIdxPicks.includes(holeIdx)) {
                holeIdx = Math.round(Math.random() * maxPossHoles);
            }
            randomIdxPicks.push(holeIdx);
            rw = this.holeDim[0] + (this.holeDim[0] * Math.round(holeIdx % this.maxCols));
            rh = (this.dh / 3) + this.holeDim[1] * Math.round(holeIdx % this.maxRows);

            this.holePosn.push([rw, rh]);
            let hole = document.createElement('div');
            hole.style['position'] = 'absolute';
            hole.style['left'] = rw + 'px';
            hole.style['top'] = rh + 'px';
            hole.classList.add('hole');
            this.playArea.prepend(hole);
        }
    }

    display() {
        let $this = this;
        if ($this.inPlay === false) {
            return;
        }
        let randomHoleIdx = Math.floor((Math.random() * $this.numHoles));
        let randomHole = $this.holePosn[randomHoleIdx];

        let dispChar = $this.dispChars[weightedRandom($this.chars.length, 0)];
        let char = document.createElement('div');
        char.style['position'] = 'absolute';
        char.style['left'] = randomHole[0] + 'px';
        char.style['top'] = (randomHole[1] - $this.holeDim[0]) + 'px';
        char.classList.add('animated', 'slideInUp', 'char', dispChar, 'smile');
        char.addEventListener('click', function(ev) {
            let el = ev.target as HTMLElement;
            let klasses = el.classList;
            if (!klasses.contains('slideOutDown') && !klasses.contains('clicked')) {
                el.classList.remove('smile');
                if (dispChar === $this.whackChar) {
                    el.classList.add(dispChar, 'shock', 'clicked', 'slideOutDown');
                    $this.score += 1;
                    $this.hits += 1;
                } else {
                    el.classList.add(dispChar, 'smile', 'clicked', 'slideOutDown');
                    $this.score -= 1;
                    $this.misses += 1;
                }
                if ($this.audioOn === true) {
                    $this.playRandomAudio(dispChar);
                }
                $this.scoreContainer.innerText = $this.score.toString();
                $this.hitsContainer.innerText = $this.hits.toString();
                $this.missesContainer.innerText = $this.misses.toString();
                let levelTxt = $this.getLevelTxt();
                if ($this.score > 0 && $this.score % $this.perLevelScore[levelTxt] ===  0) {
                    $this.level += 1;
                    let newLevelTxt = $this.getLevelTxt();
                    $this.levelEl.innerText = newLevelTxt;
                    if (levelTxt !== newLevelTxt) {
                        $this.initTimer();
                        $this.initHoles();
                    }
                    $this.updateInterval();
                }
            }
        });
        $this.playArea.appendChild(char);

        setTimeout(function() {
            let klasses = ['animated', 'slideOutDown', 'char', dispChar];
            if (!char.classList.contains('clicked') && $this.whackChar !== dispChar) {
                char.classList.remove('smile');
                char.classList.add('smile');
            }
            char.classList.add(...klasses);
            char.addEventListener('animationend', function() {
                requestAnimationFrame(function() {
                    char.remove();
                });
            });
        }, $this.interval);

        setTimeout(function() {
            requestAnimationFrame(() => $this.display());
        }, $this.interval);
    }

    createModal() {
        let div = document.createElement('div');
        div.classList.add('opacity-bg', 'modal');
        return div;
    }

    displayScores() {
        let $this = this;
        (document.querySelector('.meta') as HTMLElement).remove();
        document.querySelectorAll('.modal').forEach(ii => ii.remove());
        let modal = $this.createModal();
        let login = '';
        if (!$this.token) {
            login = `<p class="btn auth">Sign In</p><p class="text-center">to save your scores.</p>`;
        } else {
            $this.saveScore();
            login = '<p class="btn" onclick="javascript:window.location.href=\'/leaderboard.html\';">View Leaderboard</p>';
        }
        modal.innerHTML = `<h3>Score</h3>
        <h1 class="text-center">Game: ${this.game}</h1>
        <h1 class="text-center">Hits: ${this.hits.toString()}</h1>
        <h1 class="text-center">Misses: ${this.misses.toString()}</h1>
        <h1 class="text-center">Score: ${this.score.toString()}</h1>
        ${login}
        <p class="btn mt-1" onclick="javascript:window.location.reload();">Play Again</p>
        <div id="__ad-score" class="ad"><img src="https://ads.buzzvote.com/static/img/1_1.png" alt="1px" style="width:1px;height:1px;" /></div>
        <script>requestAnimationFrame(() => fetchAd('#__ad-score'));</script>
        `;
        document.body.appendChild(modal);
        if (!$this.token) {
            modal.addEventListener("click", function(ev: Event) {
                let tgt = ev.target as HTMLElement;
                if (tgt.classList.contains("auth")) {
                    $this.auth(true);
                }
            });
            if (typeof navigator.share !== 'undefined') {
                let shareBtn = document.createElement('p');
                shareBtn.classList.add('share', 'btn');
                shareBtn.addEventListener('click', function() {
                    navigator.share({
                        url: window.location.href
                    });
                });
                modal.appendChild(shareBtn);
            }
        }
    }

    pickWhackChar() {
        let $this = this;
        let charContainers = '';
        for (let ii = 0; ii < $this.chars.length; ii++) {
            let char = $this.chars[ii];
            charContainers += ''
                + '<div class="char_container">'
                + '  <a href="javascript:void(0)" data-char="' + char + '">'
                + '    <span class="char ' + char + '"></span>'
                + '    <p>' + char.split("_").join(" ") + '</p>'
                + '  </a>'
                + '</div>'
        }
        let levelContainers = '';
        let levels = Object.keys($this.levelThresholds);
        for (let ii = 0; ii < levels.length; ii++) {
            let level = levels[ii];
            levelContainers += ''
                + '<div class="level_container">'
                + '  <a href="javascript:void(0)" data-level="' + level + '">'
                +      level
                + '  </a>'
                + '  </a>'
                + '</div>'
        }

        let pickerHolder = document.createElement('div');
        pickerHolder.classList.add('opacity-bg', 'picker');
        pickerHolder.innerHTML = ''
            + '<h3>Pick a character to WHACK!</h3>'
            + `${charContainers}`
            + '<h3>Select a level</h3>'
            + `${levelContainers}`
            + '<p class="btn">Start</p>'
            + '';
        if (!$this.token) {
            pickerHolder.innerHTML += `<p class="btn auth">Sign In</p>`;
        }
        document.body.appendChild(pickerHolder);
        pickerHolder.querySelectorAll('.char_container a').forEach(function(ii: HTMLElement) {
            ii.addEventListener('click', function(ev: Event) {
                pickerHolder.querySelectorAll('.char_container.selected').forEach(function(ii) {
                    ii.classList.remove('selected');
                });
                $this.whackChar = ii.getAttribute('data-char') as string;
                $this.dispChars = $this.chars;
                (ii.parentNode as HTMLElement).classList.add('selected');
            });
        });
        pickerHolder.querySelectorAll('.level_container a').forEach(function(ii: HTMLElement) {
            ii.addEventListener('click', function(_: Event) {
                pickerHolder.querySelectorAll('.level_container.selected').forEach(function(ii) {
                    ii.classList.remove('selected');
                });
                $this.level = $this.levelThresholds[ii.getAttribute('data-level') as string] - $this.levelThresholds['Easy'];
                $this.level = $this.level ? $this.level : 1;
                (ii.parentNode as HTMLElement).classList.add('selected');
            });
        });
        (pickerHolder.querySelector('.btn') as HTMLElement).addEventListener('click', function() {
            if ($this.whackChar) {
                $this.start();
                pickerHolder.remove();
            }
        });
        if (!$this.token) {
            (document.querySelector('.auth') as HTMLElement).addEventListener('click', function() {
                (document.querySelector('.picker') as HTMLElement).classList.add('hidden');
                $this.auth(false);
            });
        }
    }

    saveScore() {
        let $this = this;
        let fd = new FormData();
        return new Promise((resolve, reject) => {
            fd.append("token", $this.token);
            fd.append("score", $this.score.toString());
            fd.append("hits", $this.hits.toString());
            fd.append("misses", $this.misses.toString());
            fd.append("char", $this.whackChar);
            fd.append("game", $this.gameUuid);
            fetch("/api/1/score/", {
                method: "POST",
                body: fd,
                credentials: "same-origin"
            }).then(function(response: Response) {
                if (response.ok) {
                    response.json().then(function(data: any) {
                        resolve(data);
                    });
                } else {
                    reject(response);
                }
            });
        });
    }

    auth(saveScore: boolean) {
        let $this = this;
        $this._login(saveScore);
    }

    _login(saveScore: boolean) {
        let $this = this;
        document.querySelectorAll('.modal').forEach(ii => ii.remove());
        let modal = $this.createModal();
        document.body.appendChild(modal);
        let auth = new Auth(modal);
        auth.render();
        auth.initLogin(function(username: string, token: string) {
            localStorage.setItem('username', username);
            $this.username = username;
            localStorage.setItem('token', token);
            $this.token = token;
            if (saveScore === true) {
                $this.saveScore().then(function() {
                    window.location.href = "/leaderboard.html";
                });
            } else {
                window.location.href = '/?g=default';
            }
        });
        (document.querySelector('.section.login a.forgot_password') as HTMLAnchorElement).addEventListener('click', function() {
            $this._passwordReset();
        });
        (document.querySelector('.section.login .btn.signup') as HTMLAnchorElement).addEventListener('click', function() {
            $this._signup(saveScore);
        });
    }

    _signup (saveScore: boolean) {
        let $this = this;
        document.querySelectorAll('.modal').forEach(ii => ii.remove());
        let modal = $this.createModal();
        document.body.appendChild(modal);
        let auth = new Auth(modal);
        auth.render();
        auth.initSignup(function(username: string, token: string) {
            localStorage.setItem('username', username);
            $this.username = username;
            localStorage.setItem('token', token);
            $this.token = token;
            if (saveScore === true) {
                $this.saveScore().then(function() {
                    window.location.href = "/leaderboard.html";
                });
            } else {
                window.location.href = '/?g=default';
            }
        });
        (document.querySelector('.section.signup .btn.login') as HTMLElement).addEventListener('click', function() {
            $this._login();
        });
    }

    _passwordReset() {
        let $this = this;
        document.querySelectorAll('.modal').forEach(ii => ii.remove());
        let modal = $this.createModal();
        document.body.appendChild(modal);
        let auth = new Auth(modal);
        auth.render();
        auth.initPasswordReset(function() {
            let info = document.createElement('p');
            info.classList.add('info');
            info.innerText = 'An email has been sent with the password reset instructions';
            let modal = document.querySelector('.modal') as HTMLDivElement;
            (modal.querySelector('.password_reset form input[type=submit]') as HTMLInputElement).before(info);
        });
        (document.querySelector('.section.password_reset .btn.login') as HTMLElement).addEventListener('click', function() {
            $this._login();
        });
    }

    getChars() {
        let $this = this;
        return new Promise(function(resolve, reject) {
            const params = new URLSearchParams(window.location.search);
            const uuid = params.get('g') || 'default'
            $this.gameUuid = uuid;
            fetch(`/api/1/game/${uuid}/`).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $this.game = data.name;
                        $this.CHARS = data.chars;
                        $this.chars = Object.keys($this.CHARS);
                        $this.coverImg = data.bg_image;
                        $this.fieldImg = data.field_image;
                        const style = document.createElement('style');
                        style.innerHTML = data.styles;
                        document.head.appendChild(style);
                        resolve();
                    });
                } else {
                    reject();
                }
            });
        });
    }

    start() {
        let $this = this;
        if ($this.whackChar) {
            $this.inPlay = true;
            $this.updateInterval();
            $this.createMetaEls();
            $this.initHoles();
            $this.initCursor();
            $this.initTimer();
            $this.initAudios();
            setTimeout(function() {
                requestAnimationFrame(() => $this.display());
            }, $this.interval);
        } else {
            $this.getChars().then(function() {
                $this.renderDOM();
                $this.pickWhackChar();
            });
        }
    }

    end() {
        this.inPlay = false;
        clearInterval(this.countDownTimerInt);
        this.displayScores();
    }
}

(function() {
    let game = new Game();
    game.start();
})();
