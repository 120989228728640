import AuthHTML from '../templates/auth.html'; // Parsed by Parcel Bundler

interface LoginData {
    username: string;
    token: string;
}

interface Errors {
    errors: ErrorKV;
}

interface ErrorKV {
    [key: string]: Array<string>;
}

export class Auth {
    protected container: HTMLElement;

    constructor(node: HTMLElement) {
        this.container = node;
    }

    render() {
        this.container.innerHTML = AuthHTML;
    }

    initLogin(success: Function) {
        let $this = this;
        document.querySelectorAll('.section').forEach(ii => ii.classList.add('hidden'));
        let section = $this.container.querySelector('.section.login') as HTMLDivElement;
        section.classList.remove('hidden');
        let form = section.querySelector('form') as HTMLFormElement;
        form.addEventListener('submit', function(ev: Event) {
            ev.preventDefault();
            document.querySelectorAll('.error').forEach(ii => ii.remove());
            $this.login(new FormData(form)).then(function(data: LoginData) {
                success(data.username, data.token);
            }).catch(function(errors: ErrorKV) {
                $this.displayErrors(form, errors);
            });
        });
    }

    initSignup(success: Function) {
        let $this = this;
        document.querySelectorAll('.section').forEach(ii => ii.classList.add('hidden'));
        let section = $this.container.querySelector('.section.signup') as HTMLDivElement;
        section.classList.remove('hidden');
        let form = section.querySelector('form') as HTMLFormElement;
        form.addEventListener('submit', function(ev: Event) {
            ev.preventDefault();
            document.querySelectorAll('.error').forEach(ii => ii.remove());
            $this.signup(new FormData(form)).then(function(data: LoginData) {
                success(data.username, data.token);
            }).catch(function(errors: ErrorKV) {
                $this.displayErrors(form, errors);
            });
        });
    }

    initPasswordReset(success: Function) {
        let $this = this;
        document.querySelectorAll('.section').forEach(ii => ii.classList.add('hidden'));
        let section = $this.container.querySelector('.section.password_reset') as HTMLDivElement;
        section.classList.remove('hidden');
        let form = section.querySelector('form') as HTMLFormElement;
        form.addEventListener('submit', function(ev: Event) {
            ev.preventDefault();
            document.querySelectorAll('.error').forEach(ii => ii.remove());
            $this.passwordReset(new FormData(form)).then(function(data: LoginData) {
                success(data.username, data.token);
            }).catch(function(errors: ErrorKV) {
                $this.displayErrors(form, errors);
            });
        });
    }

    displayErrors(form: HTMLFormElement, errors: ErrorKV) {
        let submit = form.querySelector('input[type=submit]') as HTMLInputElement;
        Object.keys(errors).forEach((key: string) => {
            errors[key].forEach((val: string) => {
                let error = document.createElement('p');
                error.classList.add('error');
                let dkey = key.slice(0, 1).toUpperCase() + key.slice(1);
                dkey = dkey.replace('_', ' ');
                error.innerText = `${dkey}: ${val}`;
                submit.before(error);
            });
        });
    }

    login(fd: FormData) {
        let promise = new Promise((resolve: Function, reject: Function) => {
            fetch("/api/1/login/", {
                method: "POST",
                body: fd,
                credentials: "same-origin"
            }).then(function(response: Response) {
                if (response.ok) {
                    response.json().then(function(data: LoginData) {
                        resolve(data);
                    });
                } else {
                    response.json().then(function(data: Errors) {
                        reject(data.errors || {});
                    });
                }
            });
        });
        return promise;
    }

    signup(fd: FormData) {
        let promise = new Promise((resolve: Function, reject: Function) => {
            fetch("/api/1/signup/", {
                method: "POST",
                body: fd,
                credentials: "same-origin"
            }).then(function(response: Response) {
                if (response.ok) {
                    response.json().then(function(data: LoginData) {
                        resolve(data);
                    });
                } else {
                    response.json().then(function(data: Errors) {
                        reject(data.errors || {});
                    });
                }
            });
        });
        return promise;
    }

    passwordReset(fd: FormData) {
        let promise = new Promise((resolve: Function, reject: Function) => {
            fetch("/api/1/password/reset/", {
                method: "POST",
                body: fd,
                credentials: "same-origin"
            }).then(function(response: Response) {
                if (response.ok) {
                    response.json().then(function(data: any) {
                        resolve(data);
                    });
                } else {
                    response.json().then(function(data: Errors) {
                        reject(data.errors || {});
                    });
                }
            });
        });
        return promise;
    }
}
