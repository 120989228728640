import CONSTANTS from './debug';

export function getPath(path: string) {
    let secure = CONSTANTS.DEBUG ? '' : 's';
    return `http${secure}://${CONSTANTS.HOST}${path}`;
}

export function weightedRandom(numChoices: number, highestWeightChoice: number): number {
    let arr: Array<number> = [];
    for (let ii = 0; ii < numChoices; ii++) {
        arr.push(ii);
    }
    for (let ii = 0; ii < Math.floor(Math.random()*numChoices*2); ii++) {
        arr.push(highestWeightChoice);
    }
    return arr[Math.floor(Math.random() * arr.length)];
}
